import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useReducer,
} from 'react'
import { useTranslation } from 'react-i18next'
import { AbortController } from 'node-abort-controller'
import { makeRequest } from '../../helpers'
import PageView from './page-view'
import { INITAL_STATE, dataReducer } from '../../reducers/dataReducer'
import { ACTION_TYPES } from '../../reducers/dataActionsType'

const PageController = (props) => {
  const controller = new AbortController()
  const [state, dispatch] = useReducer(dataReducer, INITAL_STATE)
  const { pathname } = props.location
  const slug = _.replace(pathname, '/', '')
  const { t, i18n } = useTranslation()

  const getPage = useCallback(async () => {
    dispatch({ type: ACTION_TYPES.FETCH_START })
    makeRequest({
      endPoint: 'pages',
      signal: controller.signal,
      params: {
        lang: i18n.language,
        slug: slug.replace('zh-hant/', ''),
      },
    }).then((resp) => {
      if (resp.status === 200) {
        dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: resp })
      } else {
        dispatch({ type: ACTION_TYPES.FETCH_ERROR, payload: resp.message })
      }
    })
  }, [slug])

  useEffect(() => {
    getPage()
    return () => {
      controller.abort()
    }
  }, [i18n.language])

  const viewProps = {
    loading: state.loading,
    data: _.get(state, 'data[0]'),
    error: state.error,
    errorMsg: state.errorMsg,
    t,
  }
  return (
    <PageView {...viewProps} />
  )
}

export default PageController
